<template>
  <div>
    <ch-layout page button>
      <template #button>
        <ch-button @click="openAdd">新增</ch-button>
      </template>
      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  :props="{width: '100%', height: '100%', size: 'mini'}">
          <template v-slot:handle="scope">
            <ch-button type="link" @click="openEdit(scope.row)">编辑</ch-button>
            <ch-button type="link" v-if="scope.row.status===0" @click="handleUpDown(scope.row)">启用</ch-button>
            <ch-button type="delete" v-if="scope.row.status===1" @click="handleUpDown(scope.row)">禁用</ch-button>
<!--            <ch-button type="delete" @click="handleDelete(scope.row)">删除</ch-button>-->
            <!--            <ch-button type="link" @click="openWatch(scope.row)">订单记录</ch-button>-->
          </template>
        </ch-table>
      </template>

      <template v-slot:page>
        <ch-page
            type="old"
            ref="page"
            :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>
    <!-- 新增 编辑 -->
    <modal ref="modal" :get-list-function="getTableList"/>
  </div>
</template>

<script>
import Modal from "./modal";
export default {
  components: {
    Modal,
  },
  data(){
    return{
      tableList: [], // table列表
      isTableLoading: false, // table加载
      disabled: true,
      communityList:[],
      communityGroup:[],
      typeOption:[{
        name:'家政',
        id:1,
      }],
      selectedOptions: [],
      firstList:[],
      secondList:[],
      typeStrList:['增购服务','增加人数','增加时长'],
      treeSelect:{
        add:false,
        edit:false,
        open:false,
        stop:false,
      }
    }
  },
  computed:{
    tableOption:function (){
      return [
        { column: "type", prop: "", type: "selection", width: "25px" },
        { column: "type", label: "序号", prop: "", type: "index", width: "70",},
        { column: "text", label: "类型", prop: "_type", showOverflowTooltip: true,},
        { column: "text", label: "分类", prop: "_classify", showOverflowTooltip: true,},
        { column: "text", label: "增购服务", prop: "name", showOverflowTooltip: true,},
        { column: "text", label: "描述", prop: "describe", showOverflowTooltip: true,width: "300",},
        { column: "text", label: "价格(元)", prop: "price", showOverflowTooltip: true,},
        { column: "text", label: "创建时间", prop: "createTime", showOverflowTooltip: true,},
        { column: "text", label: "更新时间", prop: "updateTime", showOverflowTooltip: true,},
        { column: "text", label: "状态", prop: "_status", showOverflowTooltip: true,},
        { column: "slot", label: "操作", slotName: "handle", width: "150",fixed: "right" },
      ];
    },
  },
  mounted() {
    this.getTableList()
  },
  methods:{
    async getClassify(){
      await this.$curl.get('/hm/classify/firstList').then(res=>{
        this.firstList = res.data
      })
    },
    // 服务分类 二级
    async getSecondList(){
      await this.$curl.get('/hm/classify/secondList').then(res=>{
        this.secondList = res.data
      })
    },
    // 列表数据
    async getTableList() {
      await this.getClassify()
      await this.getSecondList()
      this.isTableLoading = true;
      this.$refs?.page?.getServerList("/hm/goodsAppreciation/list").then((res) => {
        let row = res.rows
        this.tableList = row.map((item) => {
          let classifyList = this.firstList.concat(this.secondList)
          let classifyName = classifyList.filter(cl => item.classifyId===cl.id)[0]?.name

          let parentId = classifyList.filter(cl => item.classifyId===cl.id)[0]?.parentId
          let classifyParentName =  parentId===0? ' (一级)': ' (二级)'
          return {
            ...item,
            _status:item.status===0?'禁用':'启用',
            _type: this.typeStrList[item.type],
            _classify:classifyName + classifyParentName,
          }
        })
        this.isTableLoading = false
      }).catch(() => {this.isTableLoading = false});
    },
    // 新增
    openAdd(){
      this.$refs.modal.openAdd()
    },
    // 编辑
    openEdit(row){
      this.$refs.modal.openEdit(JSON.stringify(row))
    },
    // 启用禁用
    handleUpDown(row) {
      let text = row.status===0? '启用':'禁用'
      this.$confirm(`${text}本增购服务, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let status = row.status===0? 1:0
        this.$curl.put(`/hm/goodsAppreciation/updateStatus/${row.id}/${status}`)
            .then(() => {
              this.$message.success("操作成功！");
              this.getTableList()
            })
      })
    },
  }
}
</script>

<style scoped>

</style>